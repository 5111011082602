body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  /*scrollbar-width: none; !* Firefox *!*/
  /*-ms-overflow-style: none;  !* Internet Explorer 10+ *!*/
}

::-webkit-scrollbar { /* WebKit */
    /*width: 0;*/
    /*height: 0;*/
}

.carousel .slide {
  background-color: transparent;
}

.slider.animated {
  transition-duration: 0.5s !important;
}

:focus {
  outline: none;
}

/* this is here to fix the TOU list padding */
ol {
    padding-inline-start: 25px;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/Inter-ExtraBold-slnt=0.ttf) format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/Inter-Bold-slnt=0.ttf) format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/Inter-SemiBold-slnt=0.ttf) format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/Inter-Regular-slnt=0.ttf) format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/Inter-Light-slnt=0.ttf) format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/Inter-ExtraLight-slnt=0.ttf) format('ttf');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/NotoSans-Bold.ttf) format('ttf');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/NotoSans-Regular.ttf) format('ttf');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/NotoSans-BoldItalic.ttf) format('ttf');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/NotoSans-Italic.ttf) format('ttf');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/Helvetica_Neue_700.ttf) format('ttf');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/Helvetica_Neue_600.ttf) format('ttf');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/Helvetica_Neue_500.ttf) format('ttf');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/Helvetica_Neue_300.ttf) format('ttf');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/raleway_400.woff2) format('woff2');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/raleway_500.woff2) format('woff2');
}

@font-face {
  font-family: 'ReadexPro';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/ReadexPro-Bold.ttf) format('woff2');
}

@font-face {
  font-family: 'ReadexPro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/ReadexPro-SemiBold.ttf) format('woff2');
}

@font-face {
  font-family: 'ReadexPro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/ReadexPro-Medium.ttf) format('woff2');
}

@font-face {
  font-family: 'ReadexPro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/ReadexPro-Regular.ttf) format('woff2');
}

@font-face {
  font-family: 'ReadexPro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/ReadexPro-Light.ttf) format('woff2');
}

@font-face {
  font-family: 'ReadexPro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://allianzaidav3-prod.oss-ap-southeast-3.aliyuncs.com/portal/fonts/ReadexPro-ExtraLight.ttf) format('woff2');
}
